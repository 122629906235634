const enableLogs = process.env.REACT_APP_ENABLE_LOGS === '1';
const enableWarnings = process.env.REACT_APP_ENABLE_WARNINGS === '1';
const enableErrors = process.env.REACT_APP_ENABLE_ERRORS === '1';

export const logMessage = (...args) => {
    if (enableLogs) {
        console.log('\x1b[34m%s\x1b[0m', ...args);
    }
};

export const logWarning = (...args) => {
    if (enableWarnings) {
        console.warn('\x1b[33m%s\x1b[0m', ...args);
    }
};

export const logError = (...args) => {
    if (enableErrors) {
        console.error('\x1b[31m%s\x1b[0m', ...args);
    }
};

const appDebugger = {
    logMessage,
    logWarning,
    logError,
};

export default appDebugger;
