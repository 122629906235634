import * as api from '@/services/strapi/rest';
import store from '@/store';
import { fetchedUserPermissions } from '@/store/slices/auth';

// Define collection type basename
const basename = 'metrics-accounts';

// Get Account User Permissions
export const getCurrentUserPermissions = async (id, key = null) => {
    const endpoint = `${basename}/${id}/my-user-permissions`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        return {};
    } else {
        store.dispatch(fetchedUserPermissions({ key: key ? key : id, data: response }));
        return response;
    }
};

// Get All Account User Permissions
export const getAllAccountUserPermissions = async (id) => {
    const endpoint = `${basename}/${id}/user-permissions`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        return {};
    } else {
        return response;
    }
};

// Update Account User Policies
export const updateAccountUserPermissions = async (id, body = new FormData()) => {
    const endpoint = `${basename}/${id}/user-permissions`;
    const response = await api.restStrapiPutRequest(endpoint, true, body);
    if (response.error) {
        return {};
    } else {
        return response;
    }
};
