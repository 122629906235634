import * as api from '@/services/strapi/rest';
import store from '@/store';
import { fetchedToolSettings } from '@/store/slices/tools';

/** Get Setting  */
export const getSettings = async (uid) => {
    const endpoint = `${uid}/settings`;
    const response = await api.restStrapiGetRequest(endpoint, true);
    if (response.error) {
        return {};
    } else {
        store.dispatch(fetchedToolSettings({ key: uid, data: response }));
        return response;
    }
};

/** Set Setting  */
export const setSettings = async (uid, body = new FormData()) => {
    const endpoint = `${uid}/settings`;
    const response = await api.restStrapiPostRequest(endpoint, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedToolSettings({ key: uid, data: response }));
        return response;
    }
};
