import store from '@/store';
import * as api from './rest';
import { fetchedAll, fetchedOne, deletedOne } from '@/store/slices/users';

const basename = 'users';

// Get current user
export const apiGetMe = async () => {
    const response = await api.restStrapiGetRequest(`${basename}/me`, true);
    if (response.error) {
        return { error: response.error };
    } else {
        store.dispatch(fetchedOne(response));
        return response;
    }
};

// Get all users
export const getAllUsers = async () => {
    const response = await api.restStrapiGetRequest(`${basename}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedAll(response));
        return response;
    }
};

// Create new user
export const createUser = async (body = new FormData()) => {
    const response = await api.restStrapiPostRequest(`${basename}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedOne(response));
        return response;
    }
};

// Update user
export const updateUser = async (id, body = new FormData()) => {
    const response = await api.restStrapiPutRequest(`${basename}/${id}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(fetchedOne(response));
        return response;
    }
};

// Delete user
export const deleteUser = async (id) => {
    const response = await api.restStrapiDeleteRequest(`${basename}/${id}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(deletedOne({ id: response }));
        return response;
    }
};
