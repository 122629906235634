import * as api from '@/services/strapi/rest';

/** Define collection type basename */
const basename = 'metrics-accounts';

/** Get Integration Components  */
export const getIntegrationComponents = async (id) => {
    const response = await api.restStrapiGetRequest(`${basename}/${id}/integrations/`, true);
    if (response.error) {
        return response;
    } else {
        return response;
    }
};

/** Get Integration Components  */
export const getIntegrationAuthUrl = async (id, componentID, query) => {
    const queryString = api.createQueryString({}, query);
    const response = await api.restStrapiGetRequest(`${basename}/${id}/integrations/${componentID}/getAuthUrl/?${queryString}`, true);
    if (response.error) {
        return response;
    } else {
        return response;
    }
};

/** Set Access Tokens  */
export const setAccessTokens = async (id, componentID, body = new FormData(), query) => {
    const queryString = api.createQueryString({}, query);
    const response = await api.restStrapiPostRequest(`${basename}/${id}/integrations/${componentID}/setAccessTokens/?${queryString}`, true, body);
    if (response.error) {
        return response;
    } else {
        return response;
    }
};
