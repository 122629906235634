import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar';

const BodySingleVideo = ({ report }) => {
    // Deconstruct
    const { parameters, results } = report;

    const videoTitle = results && results.video ? results.video.title : '-';

    const Snippet = () => {
        return (
            <div className='snippet'>
                <p className='snippterHeader'>
                    <span className='snipptTitle'>{videoTitle}</span>
                    <ProgressBar report={report} />
                </p>
            </div>
        );
    };

    const Stats = () => {
        return (
            <p className='stats'>
                <span>Video</span>
                <span className='delimiter'>|</span>
                <span>{parameters.videoID}</span>
            </p>
        );
    };

    return (
        <>
            <Snippet />
            <Stats />
        </>
    );
};

BodySingleVideo.propTypes = {
    report: PropTypes.object.isRequired,
};

export default BodySingleVideo;
