import qs from 'qs';
import { getStoredUserToken } from './auth';
import appDebugger from '@/util/debugger';

const host = process.env.REACT_APP_STRAPI_API_HOST;

// Returns request Headers
const getRequestHeaders = (authenticated) => {
    // Default headers
    let headers = { Accept: 'application/json' };
    // Authentication (if applicable)
    if (authenticated) {
        const jwt = getStoredUserToken();
        headers['Authorization'] = 'Bearer ' + jwt;
    }
    return headers;
};

// Returns query
export const createQueryString = (baseQuery, overrideQuery = {}) => {
    const query = Object.assign({ ...baseQuery }, { ...overrideQuery });
    return qs.stringify(query);
};

// GET request to strapi endpoint
export const restStrapiGetRequest = async (endpoint, authenticated) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'GET',
        headers: headers,
    });
    appDebugger.logMessage('GET Request', endpoint);
    try {
        const json = await res.json();
        if (json.error) {
            appDebugger.logError('Response', endpoint, json);
        } else {
            appDebugger.logMessage('Response', endpoint, json);
        }
        return json;
    } catch (error) {
        appDebugger.logError('Error', endpoint, error);
        return { error: error };
    }
};

// POST request to strapi endpoint
export const restStrapiPostRequest = async (endpoint, authenticated, body) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'POST',
        headers: headers,
        body: body,
    });
    appDebugger.logMessage('POST Request', endpoint, body);
    try {
        const json = await res.json();
        if (json.error) {
            appDebugger.logError('Response', endpoint, json);
        } else {
            appDebugger.logMessage('Response', endpoint, json);
        }
        return json;
    } catch (error) {
        appDebugger.logMessage('Error', endpoint, error);
        return { error: error };
    }
};

// PUT request to strapi endpoint
export const restStrapiPutRequest = async (endpoint, authenticated, body) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'PUT',
        headers: headers,
        body: body,
    });
    appDebugger.logMessage('PUT Request', endpoint, body);
    try {
        const json = await res.json();
        if (json.error) {
            appDebugger.logError('Response', endpoint, json);
        } else {
            appDebugger.logMessage('Response', endpoint, json);
        }
        return json;
    } catch (error) {
        appDebugger.logError('Error', endpoint, error);
        return { error: error };
    }
};

// DELETE request to strapi endpoint
export const restStrapiDeleteRequest = async (endpoint, authenticated) => {
    const headers = getRequestHeaders(authenticated);
    const res = await fetch(`${host}/${endpoint}`, {
        method: 'DELETE',
        headers: headers,
    });
    appDebugger.logMessage('DELETE Request', endpoint);
    try {
        const json = await res.json();
        if (json.error) {
            appDebugger.logError('Response', endpoint, json);
        } else {
            appDebugger.logMessage('Response', endpoint, json);
        }
        return json;
    } catch (error) {
        appDebugger.logError('Error', endpoint, error);
        return { error: error };
    }
};
