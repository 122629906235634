import React, { useEffect, useState } from 'react';
import { useIsMounted } from '@/util/hooks';
import PropTypes from 'prop-types';

const SyncStatus = ({ account }) => {
    // State
    const [syncStatus, setSyncStatus] = useState({});
    const [repeater, setRepeater] = useState(null);

    // Hooks
    const isMounted = useIsMounted();

    useEffect(() => {
        account.getSyncStatus().then((data) => {
            if (data.status === 'syncing' || data.status === 'error') {
                isMounted() && setSyncStatus(data);
            }
        });

        const repeaterFunction = () => {
            account.getSyncStatus().then((data) => {
                isMounted() && setSyncStatus(data);
                if (data.status !== 'syncing') {
                    clearInterval(repeater);
                    account.refreshAccount().then();
                }
            });
        };

        if (account.syncing) {
            setRepeater(setInterval(repeaterFunction, 2000));
        } else {
            clearInterval(repeater);
        }

        return () => {
            setSyncStatus({});
            clearInterval(repeater);
        };
    }, [account]);

    if (syncStatus) {
        switch (syncStatus.status) {
            case 'syncing':
                return (
                    <p className='syncing'>
                        <span className='icon'>
                            <i className='fas fa-sync fa-spin' />
                        </span>
                        {syncStatus.message}
                    </p>
                );
            case 'success':
                return <p className='success'>{syncStatus.message}</p>;
            case 'error':
                return <p className='error'>{syncStatus.message}</p>;
            default:
                return '';
        }
    } else {
        return '';
    }
};

SyncStatus.propTypes = {
    account: PropTypes.object.isRequired,
};

export default SyncStatus;
