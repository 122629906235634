import * as api from '@/services/strapi/rest';
import store from '@/store';
import { fetchedOne, sanitizeEntity } from '@/store/slices/accounts';

// Define collection type basename
const basename = 'metrics-accounts';

// Default Query
export const defaultQuery = {
    populate: '*',
    pagination: {
        start: 0,
        limit: 100,
    },
};

// Get Account Sync Config
export const getSyncConfig = async (id) => {
    const response = await api.restStrapiGetRequest(`${basename}/${id}/sync-config`, true);
    if (response.error) {
        return {};
    } else {
        return response;
    }
};

// Update Account Default Sync Config
export const setDefaultSync = async (id, body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPutRequest(`${basename}/${id}/sync-config/default-sync/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Get Account Sync Cron Jobs
export const getSyncCronJobs = async (id) => {
    const response = await api.restStrapiGetRequest(`${basename}/${id}/get-sync-cron-jobs`, true);
    if (response.error) {
        return {};
    } else {
        return response;
    }
};

// Add Account Sync Cron Job
export const addSyncCronJob = async (id, body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPutRequest(`${basename}/${id}/add-sync-cron-job/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Remove Account Sync Cron Job
export const removeSyncCronJob = async (id, body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPutRequest(`${basename}/${id}/remove-sync-cron-job/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Start Quick Account Data Sync
export const startQuickDataSync = async (id, query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPostRequest(`${basename}/${id}/start-quick-data-sync/?${queryString}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Start Custom Account Data Sync
export const startCustomDataSync = async (id, body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPostRequest(`${basename}/${id}/start-custom-data-sync/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Stop Account Sync
export const stopDataSync = async (id) => {
    const response = await api.restStrapiPostRequest(`${basename}/${id}/stop-data-sync`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Get Account Sync Status
export const getSyncStatus = async (id) => {
    const data = await api.restStrapiGetRequest(`${basename}/${id}/sync-status`, true);
    if (data.error) {
        return {};
    }
    return data;
};
