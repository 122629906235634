import * as api from "@/services/strapi/rest";
import store from "@/store";
import {fetchedOne, sanitizeEntity} from "@/store/slices/accounts";


/** Define collection type basename */
const basename = "metrics-accounts";

/** Get Setting  */
export const getSetting = async (id, key) => {
    const response = await api.restStrapiGetRequest( `${basename}/${id}/settings/${key}`, true )
    if( response.error ){
        return {};
    }else{
        return response;
    }
}

/** Set Setting  */
export const setSetting = async (id, key, body = new FormData()) => {
    const response = await api.restStrapiPostRequest( `${basename}/${id}/settings/${key}`, true, body)
    if( response.error ){
        return {error: response.error.message}
    }else{
        const entity = sanitizeEntity( response );
        store.dispatch( fetchedOne(entity) );
        return entity;
    }
}
