import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectorReports } from '@/store/slices/reports';
import Report from '../Report/Report';
import Pagination from '../../Pagination';
import { LoadingIcon } from '@/components/ui';
import styles from './ReportList.module.scss';

const ReportList = ({ tool }) => {
    // Router
    const { reportId } = useParams();
    const location = useLocation();

    // States
    const [favoritesOnly, setFavoritesOnly] = useState(false);
    const [pollingTimer, setPollingTimer] = useState(null);
    const [refreshing, setRefreshing] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [reportCount, setReportCount] = useState(0);

    // Constants
    const paginationLimit = 10;
    const pageCount = useMemo(() => Math.ceil(reportCount / paginationLimit), [reportCount]);

    // Selectors
    const reports = useSelector((state) => selectorReports(state, tool.uid));

    // Pagination
    const pagination = {
        page: currentPage,
        pageSize: paginationLimit,
    };

    // Filters
    const filters = {};
    if (favoritesOnly) {
        filters.favorite = true;
    }

    // Get reports
    useEffect(() => {
        setRefreshing(true);
        tool.getReports(pagination, filters).then((res) => {
            if (res.pagination) {
                setReportCount(res.pagination.total);
            }
            setRefreshing(false);
        });
    }, [currentPage, favoritesOnly]);

    // Polling if there are pending reports
    useEffect(() => {
        if (pollingTimer) {
            clearTimeout(pollingTimer);
        }
        const processingStatus = ['init', 'pending', 'processing', 'timeout'];
        const hasProcessingReports = reports.find((report) => processingStatus.includes(report.status));
        if (hasProcessingReports) {
            const timer = setTimeout(() => {
                tool.getReports(pagination, filters).then((res) => {
                    if (res.pagination) {
                        setReportCount(res.pagination.total);
                    }
                    setRefreshing(false);
                });
            }, 5000);
            setPollingTimer(timer);
        }

        // Cleanup
        return () => {
            if (pollingTimer) {
                clearTimeout(pollingTimer);
            }
        };
    }, [reports]);

    // Handler: Toggle favorite
    const onFavoritesOnlyClick = () => {
        setFavoritesOnly(!favoritesOnly);
        setCurrentPage(1);
    };

    // Render
    const reportItems = useMemo(() => {
        const items = reports.map((report) => {
            const selected = reportId == report.id;
            return (
                <div key={report.id}>
                    <Link to={`${tool.url}${report.id}/` + location.search}>
                        <Report tool={tool} report={report} selected={selected} />
                    </Link>
                </div>
            );
        });
        return items;
    }, [reports, reportId, currentPage, location]);

    // Favorites Filter
    const FavoritesOnly = () => {
        return (
            <div className='favorites-only'>
                <span className={favoritesOnly ? 'icon-true' : 'icon-false'} onClick={() => onFavoritesOnlyClick()}>
                    <i className='fa-solid fa-star' />
                </span>
            </div>
        );
    };

    // Fetching Status
    const Fetching = () => {
        return <span className='loading'>{refreshing && <LoadingIcon />}</span>;
    };

    return (
        <div className={styles.module}>
            <div className='actions'>
                <h4>Past Reports</h4>
                <Fetching />
                <Pagination page={currentPage} limit={10} total={reportCount} setPage={setCurrentPage} setLimit={() => {}} />
                <FavoritesOnly />
            </div>
            <div className='list-wrapper'>{reportItems}</div>
        </div>
    );
};

ReportList.propTypes = {
    tool: PropTypes.object.isRequired,
};

export default ReportList;
