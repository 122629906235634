import React, { useState, useRef, useLayoutEffect, useEffect, Fragment } from 'react';
import styles from './ImportData.module.scss';
import PropTypes from 'prop-types';
import LoadingIcon from '@/components/ui/LoadingIcon/LoadingIcon';
import { Button, File, Select } from '@/components/ui/FormFields';
import { createCSVFileDownload } from '@/util/files';
import { useIsMounted } from '@/util/hooks';

const ImportData = ({ account }) => {
    // State
    const [fetching, setFetching] = useState(false);
    const [dataType, setDataType] = useState('');
    const [importFile, setImportFile] = useState(null);
    const [dataImports, setDataImports] = useState([]);
    const [syncTimeout, setSyncTimeout] = useState(null);

    // Refs
    const refFileInput = useRef();

    // Hooks
    const isMounted = useIsMounted();

    // Get initial data imports
    useLayoutEffect(() => {
        setFetching(true);
        account.getDataImportEntries().then((response) => {
            console.log('set', response);
            setDataImports(response);
            isMounted() && setFetching(false);
        });
    }, [account]);

    // Set up timeout check if any data import is syncing
    useEffect(() => {
        const anyImportSyncing = dataImports.find((dataImport) => dataImport.syncing);
        clearTimeout(syncTimeout);
        if (anyImportSyncing) {
            const timeoutFunction = setTimeout(() => {
                account.getDataImportEntries().then((response) => {
                    console.log('set', response);
                    setDataImports(response);
                    isMounted() && setFetching(false);
                });
            }, 5000);
            setSyncTimeout(timeoutFunction);
        }
    }, [account, dataImports]);

    console.log(dataImports);

    // Event Handlers
    const onDataTypeChange = (value) => {
        setDataType(value);
        setImportFile(null);
    };

    const onDownloadTemplate = () => {
        account.getDataImportSchema(dataType).then((response) => {
            if (response.hasOwnProperty('headers')) {
                const csvContent = response['headers'].join(',');
                const filename = `import-template-${dataType}`;
                createCSVFileDownload(csvContent, filename);
            }
        });
    };

    const onFileChange = () => {
        const fileInput = refFileInput.current;
        if (fileInput.files[0]) {
            setImportFile(fileInput.files[0]);
        } else {
            setImportFile(null);
        }
    };

    const onImportFile = () => {
        const fileInput = refFileInput.current;
        if (fileInput.files[0]) {
            const data = { dataType: dataType };
            const file = fileInput.files[0];
            account.createDataImport(data, file).then((response) => {
                if (!response.error) {
                    setImportFile(null);
                    onSyncImport(response['id']);
                }
            });
        }
    };

    const onSyncImport = (dataImportID) => {
        account.startDataImport(dataImportID).then(() => {
            setFetching(true);
            account.getDataImportEntries().then((response) => {
                setDataImports(response);
                isMounted() && setFetching(false);
            });
        });
    };

    const onDeleteImport = (dataImportID) => {
        account.deleteDataImport(dataImportID).then(() => {
            setFetching(true);
            account.getDataImportEntries().then((response) => {
                setDataImports(response);
                isMounted() && setFetching(false);
            });
        });
    };

    // Render: Import Form
    const renderImportForm = () => {
        const dataTypeOptions = account.getRelatedCollectionTypes().map((item) => {
            return {
                name: item.name,
                value: item.id,
            };
        });

        return (
            <div className='importForm'>
                <p>1. Choose data type to import</p>
                <Select
                    field={{
                        options: dataTypeOptions,
                        value: dataType,
                        required: true,
                        onChange: (name, value) => onDataTypeChange(value),
                    }}
                />
                <p>2. Download template CSV</p>
                <Button
                    field={{
                        value: 'Download Template',
                        disabled: !dataType,
                        onClick: onDownloadTemplate,
                    }}
                />
                <p>3. Upload filled CSV</p>
                <File
                    field={{
                        inputRef: refFileInput,
                        value: importFile ? importFile.name : '',
                        placeholder: 'Upload CSV File',
                        onChange: onFileChange,
                    }}
                />
                <Button
                    field={{
                        value: 'Import Data',
                        disabled: !dataType || !importFile,
                        onClick: onImportFile,
                    }}
                />
            </div>
        );
    };

    // Render Import Table
    const renderImportTable = () => {
        const tableRows = dataImports.map((item, key) => {
            const date = new Date(item.createdAt);
            const relatedCollectionType = account.getRelatedCollectionTypes().find((collectionType) => collectionType.id === item.dataType);
            const getStatusMessage = () => {
                if (item.syncStatus) {
                    switch (item.syncStatus.status) {
                        case 'syncing':
                            return (
                                <span className='syncing'>
                                    <i className='fas fa-sync fa-spin' />
                                    {item.syncStatus.message}
                                </span>
                            );
                        case 'success':
                            return <span className='success'>{item.syncStatus.message}</span>;

                        case 'error':
                            return <p className='error'>{item.syncStatus.message}</p>;
                    }
                } else {
                    return <span className='warning'>Data has not been imported.</span>;
                }
            };

            return (
                <Fragment key={key}>
                    <div className='gridItem itemStyleCell'>
                        {relatedCollectionType ? (
                            <span className='dataType'>{relatedCollectionType.name}</span>
                        ) : (
                            <span className='error'>Error: Data type not found.</span>
                        )}
                    </div>
                    <div className='gridItem itemStyleCell'>
                        {item.csvFile ? (
                            <a className='fileLink' href={`${process.env.REACT_APP_STRAPI_HOST}${item.csvFile.url}`}>
                                <i className='fas fa-file' />
                                {item.csvFile.name}
                            </a>
                        ) : (
                            <span className='error'>Error: No file attached</span>
                        )}
                    </div>
                    <div className='gridItem itemStyleCell'>
                        <span className='date'>{date.toLocaleString()}</span>
                    </div>
                    <div className='gridItem itemStyleCell rightAlign'>
                        <span className='status'>{getStatusMessage()}</span>
                    </div>
                    <div className='gridItem itemStyleCell rightAlign'>
                        <div className='actionItems'>
                            <Button field={{ inputClass: 'buttonImport', value: 'Re-Import', onClick: () => onSyncImport(item.id) }} />
                            <Button field={{ inputClass: 'buttonDelete', value: 'Delete', onClick: () => onDeleteImport(item.id) }} />
                        </div>
                    </div>
                </Fragment>
            );
        });

        const noItemsRow = <div className='gridItem noImportsCell'>{fetching ? <LoadingIcon /> : 'No Imports Found'}</div>;

        return (
            <div className='importTable'>
                <div className='gridItem itemStyleHeader headerCell'>Data Imports</div>
                {tableRows.length ? tableRows : noItemsRow}
            </div>
        );
    };

    return (
        <div className={styles.module}>
            <h2>Import Account Data</h2>
            {renderImportForm()}
            {renderImportTable()}
        </div>
    );
};

ImportData.propTypes = {
    account: PropTypes.object.isRequired,
};

export default ImportData;
