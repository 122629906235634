export const regions = [
    {
        key: 'region-eu',
        name: 'Region: Europe',
        values: [
            'AD',
            'AL',
            'AT',
            'BY',
            'BE',
            'BA',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',
            'FR',
            'DE',
            'GI',
            'GR',
            'VA',
            'HU',
            'IS',
            'IE',
            'IT',
            'LV',
            'LI',
            'LT',
            'LU',
            'MK',
            'MT',
            'MD',
            'MC',
            'ME',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'RU',
            'SM',
            'RS',
            'SK',
            'SI',
            'ES',
            'SE',
            'CH',
            'UA',
        ],
    },
    {
        key: 'region-na',
        name: 'Region: North America',
        values: ['US', 'CA'],
    },
];

export const countries = [
    {
        value: 'all-countries',
        name: 'Global: All Countries',
    },
    {
        type: 'country',
        value: 'US',
        name: 'United States of America',
        members: 'US',
    },
    {
        type: 'country',
        value: 'CA',
        name: 'Canada',
        members: 'CA',
    },
    {
        type: 'country',
        value: 'GB',
        name: 'United Kingdom',
        members: 'GB',
    },
    {
        type: 'country',
        value: 'AU',
        name: 'Australia',
        members: 'AU',
    },
    {
        type: 'country',
        value: 'AD',
        name: 'Andorra',
        members: 'AD',
    },
    {
        type: 'country',
        value: 'AL',
        name: 'Albania',
        members: 'AL',
    },
    {
        type: 'country',
        value: 'AT',
        name: 'Austria',
        members: 'AT',
    },
    {
        type: 'country',
        value: 'BY',
        name: 'Belarus',
        members: 'BY',
    },
    {
        type: 'country',
        value: 'BE',
        name: 'Belgium',
        members: 'BE',
    },
    {
        type: 'country',
        value: 'BA',
        name: 'Bosnia and Hertzegovina',
        members: 'BA',
    },
    {
        type: 'country',
        value: 'BG',
        name: 'Bulgaria',
        members: 'BG',
    },
    {
        type: 'country',
        value: 'HR',
        name: 'Croatia',
        members: 'HR',
    },
    {
        type: 'country',
        value: 'CY',
        name: 'Cyprus',
        members: 'CY',
    },
    {
        type: 'country',
        value: 'CZ',
        name: 'Czech Republic',
        members: 'CZ',
    },
    {
        type: 'country',
        value: 'DK',
        name: 'Denmark',
        members: 'DK',
    },
    {
        type: 'country',
        value: 'EE',
        name: 'Estonia',
        members: 'EE',
    },
    {
        type: 'country',
        value: 'FI',
        name: 'Finland',
        members: 'FI',
    },
    {
        type: 'country',
        value: 'FR',
        name: 'France',
        members: 'FR',
    },
    {
        type: 'country',
        value: 'DE',
        name: 'Germany',
        members: 'DE',
    },
    {
        type: 'country',
        value: 'GI',
        name: 'Gibraltar',
        members: 'GI',
    },
    {
        type: 'country',
        value: 'GR',
        name: 'Greece',
        members: 'GR',
    },
    {
        type: 'country',
        value: 'VA',
        name: 'Vatican - Holy See',
        members: 'VA',
    },
    {
        type: 'country',
        value: 'HU',
        name: 'Hungary',
        members: 'HU',
    },
    {
        type: 'country',
        value: 'IS',
        name: 'Iceland',
        members: 'IS',
    },
    {
        type: 'country',
        value: 'IE',
        name: 'Ireland',
        members: 'IE',
    },
    {
        type: 'country',
        value: 'IT',
        name: 'Italy',
        members: 'IT',
    },
    {
        type: 'country',
        value: 'LV',
        name: 'Latvia',
        members: 'LV',
    },
    {
        type: 'country',
        value: 'LI',
        name: 'Liechtenstein',
        members: 'LI',
    },
    {
        type: 'country',
        value: 'LT',
        name: 'Lithuania',
        members: 'LT',
    },
    {
        type: 'country',
        value: 'LU',
        name: 'Luxembourg',
        members: 'LU',
    },
    {
        type: 'country',
        value: 'MK',
        name: 'Macedonia',
        members: 'MK',
    },
    {
        type: 'country',
        value: 'MT',
        name: 'Malta',
        members: 'MT',
    },
    {
        type: 'country',
        value: 'MD',
        name: 'Moldova',
        members: 'MD',
    },
    {
        type: 'country',
        value: 'MC',
        name: 'Monaco',
        members: 'MC',
    },
    {
        type: 'country',
        value: 'ME',
        name: 'Montenegro',
        members: 'ME',
    },
    {
        type: 'country',
        value: 'NL',
        name: 'Netherlands',
        members: 'NL',
    },
    {
        type: 'country',
        value: 'NO',
        name: 'Norway',
        members: 'NO',
    },
    {
        type: 'country',
        value: 'PL',
        name: 'Poland',
        members: 'PL',
    },
    {
        type: 'country',
        value: 'PT',
        name: 'Portugal',
        members: 'PT',
    },
    {
        type: 'country',
        value: 'RO',
        name: 'Romania',
        members: 'RO',
    },
    {
        type: 'country',
        value: 'RU',
        name: 'Russia',
        members: 'RU',
    },
    {
        type: 'country',
        value: 'SM',
        name: 'San Marino',
        members: 'SM',
    },
    {
        type: 'country',
        value: 'RS',
        name: 'Serbia',
        members: 'RS',
    },
    {
        type: 'country',
        value: 'SK',
        name: 'Slovakia',
        members: 'SK',
    },
    {
        type: 'country',
        value: 'SI',
        name: 'Slovenia',
        members: 'SI',
    },
    {
        type: 'country',
        value: 'ES',
        name: 'Spain',
        members: 'ES',
    },
    {
        type: 'country',
        value: 'SE',
        name: 'Sweden',
        members: 'SE',
    },
    {
        type: 'country',
        value: 'CH',
        name: 'Switzerland',
        members: 'CH',
    },
    {
        type: 'country',
        value: 'UA',
        name: 'Ukraine',
        members: 'UA',
    },
];

export const currencies = [
    {
        value: 'USD',
        name: 'USD',
    },
    {
        value: 'EUR',
        name: 'EUR',
    },
    {
        value: 'GBP',
        name: 'GBP',
    },
];
