import * as api from '@/services/strapi/rest';
import store from '@/store';
import { fetchedAll, fetchedOne, deletedOne, sanitizeEntity } from '@/store/slices/accounts';

// Define collection type basename
const basename = 'metrics-accounts';

// Default Query
export const defaultQuery = {
    populate: '*',
    pagination: {
        start: 0,
        limit: 100,
    },
};

// Get all collection entries and update the store
export const getAllEntries = async (query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiGetRequest(`${basename}/?${queryString}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entries = response.data.map((entity) => sanitizeEntity(entity));
        store.dispatch(fetchedAll(entries));
        return response.data;
    }
};

// Get specific entry in collection by ID and update the store
export const getOneEntry = async (id, query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiGetRequest(`${basename}/${id}/?${queryString}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response.data);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Get specific entry in collection by slug and update the store
export const getOneEntryBySlug = async (slug, query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiGetRequest(`${basename}/get-by-slug/${slug}/?${queryString}`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response.data);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Create entry in collection
export const createEntry = async (body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPostRequest(`${basename}/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response.data);
        store.dispatch(fetchedOne(entity));
        return entity;
    }
};

// Update entry in collection
export const updateEntry = async (id, body = new FormData(), query = {}) => {
    const queryString = api.createQueryString(defaultQuery, query);
    const response = await api.restStrapiPutRequest(`${basename}/${id}/?${queryString}`, true, body);
    if (response.error) {
        return { error: response.error.message };
    } else {
        const entity = sanitizeEntity(response.data);
        store.dispatch(updatedOne(entity));
        return entity;
    }
};

// Delete entry in collection
export const deleteEntry = async (id) => {
    const response = await api.restStrapiDeleteRequest(`${basename}/${id}/`, true);
    if (response.error) {
        return { error: response.error.message };
    } else {
        store.dispatch(deletedOne(id));
        return {};
    }
};
