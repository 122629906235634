import store, { resetStore } from '@/store';
import { apiGetMe } from './users';
import { userLoggedIn, userLoggedOut } from '@/store/slices/auth';
import { restStrapiPostRequest } from './rest';

// Endpoint basename
const basename = 'auth';

// Authenticate user
export const authenticateUser = async (identifier, password) => {
    const formData = new FormData();
    formData.append('identifier', identifier);
    formData.append('password', password);

    const response = await restStrapiPostRequest(`${basename}/local`, false, formData);
    if (response.error) {
        return { error: response.error.message };
    } else {
        localStorage.setItem('jwt', response.jwt);
        validateCurrentUser();
        return response;
    }
};

// Clear authentication session token and log user out
export const clearUserAuthentication = () => {
    localStorage.removeItem('jwt');
    resetStore();
};

// Validate current user
export const validateCurrentUser = async () => {
    // Check if JWT is stored
    const jwt = getStoredUserToken();
    if (!jwt) {
        return false;
    }

    // Check if current user already set
    const state = store.getState();
    if (state.auth.jwt === jwt && state.auth.user) {
        return true;
    }

    // Get current user
    const response = await apiGetMe();
    if (response.error) {
        store.dispatch(userLoggedOut());
        return false;
    } else {
        store.dispatch(userLoggedIn({ jwt: jwt, user: response }));
        return true;
    }
};

// Get authentication session token
export const getStoredUserToken = () => {
    return localStorage.getItem('jwt');
};

// Try to send password recovery email to user
export const sendPasswordRecoveryEmail = async (email) => {
    const formData = new FormData();
    formData.append('email', email);

    const response = await restStrapiPostRequest(`${basename}/forgot-password`, false, formData);
    if (response.error) {
        return { error: response.error.message };
    } else {
        return response;
    }
};

// Try to send account confirmation email to user
export const sendConfirmationEmail = async (email) => {
    const formData = new FormData();
    formData.append('email', email);

    const response = await restStrapiPostRequest(`${basename}/send-email-confirmation`, true, formData);
    if (response.error) {
        return { error: response.error.message };
    } else {
        return response;
    }
};

// Try to reset user's password
export const resetUserPassword = async (password, passwordConfirmation, code) => {
    const formData = new FormData();
    formData.append('password', password);
    formData.append('passwordConfirmation', passwordConfirmation);
    formData.append('code', code);

    const response = await restStrapiPostRequest(`${basename}/reset-password`, false, formData);
    if (response.error) {
        return { error: response.error.message };
    } else {
        return response;
    }
};
